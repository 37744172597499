import axios from 'axios';
import { Poppins, Quicksand } from 'next/font/google';
import React, { useEffect, useState } from 'react'
const extrapopins =Poppins({weight:"500", subsets:['latin']});
const quickSand =Quicksand({weight:"400", subsets:['latin']});
const popins =Poppins({weight:"400", subsets:['latin']});
export default function EventForm({eventName}) {

    const [formData, setFormData] = useState({
      event: '',
      name: '',
      email: '',
      mobileNumber: '',
      message:""
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  const handleSubmit = async(e) =>{
    e.preventDefault();

    try {
   const response = await axios.post(`${process.env.url}/api/eventform`,formData)

   setFormData({
    event: '',
    name: '',
    email: '',
    mobileNumber: '', 
    message:""
   })
  

  //  setTimeout(()=>{
  //    setS({    
 
  //    // submissionSuccess:false,
    
   
  //  })
  //  },6000)
 

  // Handle form submission here
 console.log("Student Data",response.data);
 //  debugger;
 onClose();  
    } catch (error) {
      console.log("Sending Error",error);
      
    }

  }
  return (
    <>
    <section className='mmt20'>
    <div className="form-popup">
              <form onSubmit={handleSubmit} className="form-container">
                <h6 className={`fs-33 mfs-24 tac  ${extrapopins.className}`}>Get a <span className={`ylw-clr ${extrapopins.className}`}> Quote </span></h6>
                <input className={`${extrapopins.className}`} type="text" name="name" placeholder='Name' value={formData.name} onChange={handleChange} required />
                
                <input className={`${extrapopins.className}`} type="email" name="email" placeholder='Email' value={formData.email} onChange={handleChange} required />
                
                <input className={`${extrapopins.className}`} type="text" name="mobileNumber" placeholder='Mobile Number' value={formData.mobileNumber} onChange={handleChange} required />
                <textarea  className={`text-area ${extrapopins.className}`} name="message" placeholder='Message' value={formData.message} onChange={handleChange} required />

                <button type="submit" className={`btn cw mt10 ${popins.className}`}>Submit</button>
                {/* <button type="button" className="btn cancel" onClick={onClose}>Cancel</button> */}
              </form>
            </div>
    </section>

    <style jsx>{`
             /* Existing styles */
        .form-popup {
          background-color: white;
          z-index: 9;
          width: 350px;
        }


        .form-container input[type="text"], 
        .form-container input[type="email"] {
          width: 100%;
          padding: 10px;
          margin: 8px 0 0 0;
          border: 1px solid #ccc;
          border-radius: 8px;
        }
     .text-area{
      width: 100%;
          padding: 10px;
          margin: 8px 0 0 0;
          border: 1px solid #ccc;
          border-radius: 8px;
     }
        .btn {
          background-color: #ffa900;
          color: white;
          border: none;
          cursor: pointer;
          width: 100%;
          margin-bottom:10px;
          border-radius: 10px;
        }

        .form-container .btn.cancel {
          background-color: #cc0000;
        }

          @media only screen and (max-width: 500px){
                 .form-popup {

          background-color: white;
          padding: 20px;
          border: 1px solid #888;
          box-shadow: 0 5px 10px rgba(0,0,0,0.3);
          z-index: 9;
          width: auto;
        }
            .form-container {
          max-width: 200px;
          padding: 10px;
          
        }
          }
    `}
</style>
    
    </>
  )
}
